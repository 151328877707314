.suggestion {
  text-align: center;
  color: #fff;
  margin-bottom: 28px; }
  .suggestion img {
    display: block;
    margin: 0 auto 34px auto; }
  .suggestion .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 10px;
    color: inherit; }
  .suggestion .description {
    font-size: 18px;
    line-height: 27px;
    display: inline-block;
    margin: 0 0 10px; }
    .suggestion .description a {
      color: white !important; }

.lang-switcher {
  position: absolute;
  top: 1em;
  right: 2em;
  color: #fff; }
  .lang-switcher a {
    color: #fff !important;
    display: inline-block; }
    .lang-switcher a + a:before {
      content: '';
      border-left: 1px solid #fff;
      margin: 0 10px; }

.item {
  background-color: white;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 30px; }
  .item.wrapp {
    margin: 0 15px; }
  .item .item-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 330px;
    justify-content: center; }
  .item h4 {
    color: #555555;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 21px;
    text-align: center; }
  .item img {
    margin-bottom: 30px;
    height: 180px; }
  .item .title {
    color: #555555;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px; }

.copyrights {
  margin-top: 22px; }
  .copyrights p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    position: relative;
    padding: 15px 0;
    display: inline-block;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .copyrights p:before {
      display: block;
      content: "";
      width: 110%;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }

button.primary {
  border: 0;
  border-radius: 4px;
  height: 56px;
  cursor: pointer;
  color: #fff;
  background: #ff8800;
  padding: 15px;
  font-size: 18px;
  min-width: 160px;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  button.primary a {
    color: #fff;
    padding: 15px;
    display: block; }
  button.primary:hover {
    background: #df7905; }

button.default {
  padding: 10px 15px;
  width: calc(100% - 10px);
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  button.default i {
    color: #332767; }

button.BackButton {
  padding: 10px 0 10px 0;
  border: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #332767;
  background: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  button.BackButton:hover {
    color: #898bd3; }
  button.BackButton i {
    color: #332767;
    margin-right: 15px; }

button.smallButton {
  border: 0;
  border-radius: 0;
  height: 40px;
  cursor: pointer;
  color: white !important;
  background: #ff8800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  min-width: 160px;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  button.smallButton i {
    color: white !important; }
  button.smallButton a {
    color: #fff;
    padding: 15px;
    display: block; }
  button.smallButton:hover {
    background: #df7905; }

.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0; }
  .main .suggestion {
    color: #fff; }
  .main .card-wrapp {
    color: #9a9a9a;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .main .card-wrapp .item:hover {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
    .main .card-wrapp .item img {
      margin-bottom: 30px;
      height: auto;
      width: 120px; }

.wrapp-form {
  width: 50%;
  margin: auto;
  background: #fff;
  padding: 40px;
  max-width: 540px; }
  .wrapp-form .suggestion {
    text-align: center;
    color: #9b9b9b; }
    .wrapp-form .suggestion span {
      color: #9b9b9b;
      font-weight: 500;
      padding-left: 5px;
      cursor: pointer; }
  .wrapp-form label {
    margin: 0;
    font-size: 12px;
    color: #555555; }
  .wrapp-form .dimenticato {
    margin-bottom: 15px;
    color: #868686;
    font-weight: 500; }
  .wrapp-form button {
    margin-top: 30px;
    width: 100%;
    height: 56px;
    padding: 14px 0;
    cursor: pointer;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: white;
    background: #ff8800;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .wrapp-form button:hover {
      background: #df7905; }
  .wrapp-form .lable-bold {
    color: #555555;
    font-size: 13px;
    font-weight: 550;
    line-height: 27px; }

.input {
  border: 2px solid #F0F0F4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  line-height: 15px;
  height: 47px;
  color: #8C96A0;
  font-family: Montserrat;
}
.input::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 15px;
  font-family: Montserrat;
}
.input::-ms-input-placeholder {
  font-size: 12px;
  line-height: 15px;
  font-family: Montserrat;
}
.input::placeholder {
  font-size: 12px;
  line-height: 15px;
  font-family: Montserrat;
}


body {
  margin: 0;
  font-family: "Open Sans" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555555;
  text-align: left;
  background-color: #fbfcfc !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px; }

.primary-bg {
  background: -webkit-linear-gradient(315deg, #332767 0%, #5c48a8 100%);
  background: linear-gradient(135deg, #332767 0%, #5c48a8 100%); }

.access-bg {
  background-image: url(/static/media/background.82e79cc4.jpg);
  background-size: cover; }
  .access-bg .suggestion .description a:hover {
    color: #898bd3 !important; }

.pink-color {
  color: #8569d0 !important; }

.dark-pink {
  color: #653399 !important; }

.navbar {
  box-shadow: none; }

label {
  height: 21px;
  width: 100%;
  color: #555555;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 0; }

tbody {
  border: 1px solid #e4dcee; }

a {
  color: #555555 !important; }

a:hover {
  color: #898bd3 !important; }

.border-bottom {
  border-bottom: 2px solid #e4dcee !important; }

.icon-add {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff8800; }

.pagination-arrows {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  background-color: white;
  border: 1px solid #e4dcee;
  border-radius: 4px;
  outline: 0px;
  cursor: pointer; }

.full-screen {
  width: 100%;
  min-height: 100vh; }
  .full-screen.centered-content {
    display: flex;
    justify-content: center;
    align-items: center; }

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 50px 0;
  color: #555555;
  font-size: 25px;
  line-height: 27px;
  text-align: center; }

.subtitle {
  margin: 0;
  color: #555555;
  font-family: "Open Sans";
  font-size: 20px;
  line-height: 27px;
  text-align: center; }

.content {
  background: #fff;
  margin: 15px 0; }

::-webkit-input-placeholder {
  color: #9b9b9b !important;
  font-size: 14px;
  line-height: 21px; }

::-ms-input-placeholder {
  color: #9b9b9b !important;
  font-size: 14px;
  line-height: 21px; }

::placeholder {
  color: #9b9b9b !important;
  font-size: 14px;
  line-height: 21px; }

.ant-radio-checked .ant-radio-inner {
  border-color: #7969b7 !important; }

.ant-radio-inner {
  border-color: #7969b7 !important; }

.ant-radio-inner::after {
  background-color: #7969b7 !important; }

.ant-checkbox-inner {
  border-color: #ff8800; }

.checkbox.ant-checkbox-wrapper {
  font-size: 16px;
  line-height: 24px;
  color: #555555; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff8800;
  border-color: #ff8800; }

a {
  color: inherit; }

.fa,
.far,
.fas {
  cursor: pointer; }

.list-group-flush {
  color: #495057; }

.center {
  text-align: center; }

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 0; }

.flexible-navbar {
  -webkit-transition: padding-left 0.5s;
  transition: padding-left 0.5s;
  background-color: #332767; }

.footer-bg {
  background-color: #332767;
  padding: 40px 0; }

.sidebar-fixed .logo-wrapper img {
  width: 100%;
  padding: 2.5rem;
  min-height: 180px; }

.list-group-item {
  display: block !important;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.list-group-item:hover {
  color: #49505b;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item:hover {
  color: #49505b;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group .active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px; }

.card-title {
  font-size: 1.5rem;
  font-weight: 600; }

.card-subtitle {
  font-size: 1.25rem;
  font-weight: 600; }

.full {
  height: 70vh; }

.language-select {
  width: 100%;
  height: 56px;
  padding: 0 10px;
  border-radius: 0px;
  border: 1px solid #ced4da;
  background-color: white; }

.Select-control {
  background-color: white;
  border-radius: 0px;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 56px;
  padding: 0 10px; }

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 13px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px; }

.Select-input {
  padding-left: 0px; }

.Select-input > input {
  width: 100%;
  margin-top: 13px; }

.bad-gateway-row {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.card .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center; }

.card h3,
.card.card-cascade h4 {
  margin-bottom: 0px; }

.cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px; }

.cascading-admin-card .admin-up .fa {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19); }

.cascading-admin-card .admin-up .fa {
  padding: 1.7rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px; }

.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right; }

.admin-up .data p {
  color: #999999;
  font-size: 12px; }

.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem; }

.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0; }

.classic-admin-card .card-body h4 {
  margin-top: 10px; }

.form-inline {
  flex-flow: unset; }

.breadcrumb {
  margin: 0; }

.activeClass .list-group-item {
  z-index: 5;
  color: #fff;
  border-color: #007bff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px !important;
  background-color: #007bff !important; }

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none; }
  .flexible-content {
    padding-left: 0; }
  .flexible-navbar {
    padding-left: 10px; }
  #content {
    margin-left: 0px; }
  .page-footer {
    margin-left: 0px; }
  .card.cascading-admin-card {
    margin-top: 2.5rem; } }

@media (max-width: 576px) {
  .card.cascading-admin-card {
    margin-top: 2.5rem; }
  #breadcrumb {
    flex-direction: column; } }

#btn-logout {
  cursor: pointer; }

.main-container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 213px; }

.main-container .page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #8C96A0 !important; }

.table {
  text-align: center;
  margin: 0 !important; }

.fa-plus {
  color: white; }

.table-head {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  padding: 16px; }

.ant-input {
  border-radius: 0px !important; }

.personalized-margin-table .ant-table-tbody > .ant-table-row > td {
  border-bottom: 0; }

.personalized-margin-table.ant-table-tbody > .ant-table-expanded-row > td {
  border-bottom: 1px solid #e8e8e8; }

.table-container .offers-table .ant-spin-nested-loading th {
  color: #555555; }

.table-container .title {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  margin: 40px 0; }

.table-container .Filters {
  margin-bottom: 30px;
  padding: 0 110px; }
  .table-container .Filters .ant-select {
    width: 100%;
    height: 40px;
    border-color: #e4dcee; }
    .table-container .Filters .ant-select .ant-select-selection,
    .table-container .Filters .ant-select .ant-select-selection__rendered {
      border: 0;
      height: 100%;
      line-height: 40px; }
      .table-container .Filters .ant-select .ant-select-selection .ant-select-selection__placeholder,
      .table-container .Filters .ant-select .ant-select-selection__rendered .ant-select-selection__placeholder {
        color: #555555; }
  .table-container .Filters input {
    height: 40px;
    border-color: #e4dcee;
    border-radius: 0;
    border: 0; }
  .table-container .Filters ::-webkit-input-placeholder {
    color: #555555 !important;
    font-size: 14px;
    line-height: 21px; }
  .table-container .Filters ::-ms-input-placeholder {
    color: #555555 !important;
    font-size: 14px;
    line-height: 21px; }
  .table-container .Filters ::placeholder {
    color: #555555 !important;
    font-size: 14px;
    line-height: 21px; }
  .table-container .Filters .ant-select-selection {
    border-color: #e4dcee;
    border-radius: 0; }
  .table-container .Filters .ant-input-group input {
    border-color: #e4dcee;
    border-radius: 0; }

.table-container .offers-table .ant-spin-nested-loading {
  background: #fff; }
  .table-container .offers-table .ant-spin-nested-loading th {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px; }
  .table-container .offers-table .ant-spin-nested-loading td i.fas {
    font-size: 16px; }
  .table-container .offers-table .ant-spin-nested-loading .ant-checkbox-inner {
    border-color: #332767;
    opacity: 0.5; }
  .table-container .offers-table .ant-spin-nested-loading .ant-checkbox-checked .ant-checkbox-inner {
    background: #332767;
    opacity: 0.5; }
  .table-container .offers-table .ant-spin-nested-loading .ant-table-thead > tr > th,
  .table-container .offers-table .ant-spin-nested-loading .ant-table-tbody > tr > td {
    border-color: #e4dcee; }

.table-container .offers-table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #332767;
  opacity: 0.5; }

.table-container .offers-table .ant-pagination.ant-table-pagination {
  width: 100%;
  text-align: center; }

.table-container .offers-table-background .ant-spin-nested-loading .ant-table-thead > tr > th {
  background-color: #5d48a8; }
  .table-container .offers-table-background .ant-spin-nested-loading .ant-table-thead > tr > th div > span {
    color: #fff;
    font-size: 16px;
    font-weight: "normal";
    font-family: "Open Sans";
    line-height: 24px;
    float: left; }
  .table-container .offers-table-background .ant-spin-nested-loading .ant-table-thead > tr > th div > button {
    height: 24px;
    width: 60.05px;
    color: #e08080;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    border: none;
    background: none;
    float: right; }
  .table-container .offers-table-background .ant-spin-nested-loading .ant-table-thead > tr > th div > button:hover {
    cursor: pointer; }

.table-container .offers-table-background .ant-spin-nested-loading .ant-table-tbody > tr > td {
  max-width: 185px; }

.table-container .offers-table-none .ant-table-body > table > thead > tr > th {
  border-bottom: none !important; }

.table-container .offers-table-none .ant-table-placeholder {
  display: none; }

.table-container .offers-table-none .ant-table-body > table > thead > tr > th {
  background-color: #fbfcfc !important; }

.ant-select-dropdown {
  border-color: #e4dcee !important;
  border-radius: 0 !important; }
  .ant-select-dropdown .ant-select-dropdown-menu-item {
    border-color: #e4dcee !important;
    border-radius: 0 !important; }

.offer-table-row {
  background-color: transparent !important;
  box-shadow: none !important; }
  .offer-table-row > td {
    padding: 10px 0 !important;
    border-top: 10px solid transparent;
    box-shadow: none !important; }

.offer-detail .actions {
  right: 20px; }
  .offer-detail .actions button {
    background: none;
    border: none;
    font-size: 20px;
    padding: 5px 10px; }
    .offer-detail .actions button.delete {
      right: 20px;
      color: #e08080; }

@-moz-document url-prefix() {
  .table-container .Filters input {
    padding: 10px; }
  .table-container .Filters .ant-input-group input {
    padding: 10px 0; } }

.sc-bdVaJa {
  cursor: pointer; }

.company-adv .item {
  min-width: 280px; }

.hand {
  cursor: pointer; }

.debug [data-component] {
  color: darkorange;
  border-style: dotted;
  border-width: 1px;
  position: relative;
  padding: 20px 5px 5px; }
  .debug [data-component]:before {
    content: attr(data-component);
    position: absolute;
    top: 0;
    right: auto;
    background: white;
    color: inherit;
    z-index: 1;
    padding: 0 5px;
    font-size: 10px;
    letter-spacing: 1px;
    font-family: monospace;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: inherit; }
  .debug [data-component] [data-component] {
    color: red; }
    .debug [data-component] [data-component] [data-component] {
      color: green; }
      .debug [data-component] [data-component] [data-component] [data-component] {
        color: dodgerblue; }
        .debug [data-component] [data-component] [data-component] [data-component] [data-component] {
          color: plum; }

.on-close {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.wrapp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: left;
    color: #333333;
    margin: 0;
}

.description {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 32px;
    color: #333333;
    font-weight: 400;
}

.label {
    font-family: Montserrat;
    font-size: 12px;
    color: #8C96A0;
}

#react-select-objectives--value {
    height: 100%;
    display: flex;
    align-items: center;
}
.description-input::-webkit-input-placeholder {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}
.description-input::-ms-input-placeholder {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}
.description-input::placeholder {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}
.description-input {
    border: 2px solid #F0F0F4;
    border-radius: 8px !important;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}

.sub-title {
    margin-top: 40px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #333333;
}
input[name=articleWriting] + label {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
    display: flex;
    align-items: center;
}

.radio-wrapper {
    display: flex;
    cursor: pointer;
    align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.outer-circle {
    width: 22px;
    height: 22px;
    background: #FFFFFF;
    border: 2px solid #EDF1F4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.inner-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1FBCE8;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
}
.unselected {
    border: 2px solid #EDF1F4;
}
.unselected-circle {
    width: 0;
    height: 0;
}
.helper-text {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}
.checkbox-wrapper {
    display: flex;
    cursor: pointer;
    align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.outer-square {
    width: 22px;
    height: 22px;
    background: #FFFFFF;
    border: 2px solid #EDF1F4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.inner-square {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background: #1FBCE8;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.unselected {
    border: 2px solid #EDF1F4;
}

.unselected-square {
    width: 0;
    height: 0;
}

.helper-text {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}
.secondary-button {
    color: #8C96A0;
    background-color: #F0F0F4;
}

.primary-button {
    color: white;
    background-color: #EF8634;
}

.custom-button {
    font-size: 14px;
    line-height: 19px;
    padding: 10px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
.select-wrapper {

}

.select-header {
    background: #FFFFFF;
    border: 2px solid #F0F0F4;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
    padding: 14px;
    cursor: pointer;

}

.select-options-container {
    position: absolute;
    width: 100%;
    z-index: 999;
    margin-top: 8px;
    padding: 0;
    background: #ffffff;
    color: #8C96A0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid #F0F0F4;
    box-sizing: border-box;
    border-radius: 8px;
}

.select-option {
    list-style: none;
    padding: 4px 14px;
}

.select-option:hover {
    background: #E5E5E5;;
}


.select-label {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
    margin-bottom: 4px;
    margin-top: 0;
}

.w-33 {
    width: 33%;
}

.error {
    border-color: #dc3545;
}
.rlv-c-filter__field {
max-width: 14%;
margin-right: 1rem;
}
.rlv-c-filter__field:last-child {
margin-right: 0 !important;
}

.table-container .Filters {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 0 !important;
}

.col-1-2 {
    flex: 0 0 12.666667%;
    max-width: 12.666667%;
}
.content.property {
  padding: 30px;
  position: relative;
  border: 2px solid #f0ecf5; }
  .content.property .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin: 0; }
  .content.property .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    margin-right: 8px; }
  .content.property .ant-row {
    margin-bottom: 15px; }
  .content.property .actions {
    position: absolute;
    right: 20px;
    top: 25px; }
    .content.property .actions button {
      background: none;
      border: none;
      font-size: 20px;
      padding: 5px 10px; }
      .content.property .actions button.delete {
        color: #e08080; }

.fa-plus {
  color: white; }

.offer-detail .actions {
  right: 20px; }
  .offer-detail .actions button {
    background: none;
    border: none;
    font-size: 20px;
    padding: 5px 10px; }
    .offer-detail .actions button.delete {
      right: 20px;
      color: #e08080; }

@-moz-document url-prefix() {
  .table-container .Filters input {
    padding: 10px; }
  .otable-container .Filters .ant-input-group input {
    padding: 10px 0; } }

.content.property {
  padding: 30px;
  position: relative;
  border: 1px solid #e4dcee; }
  .content.property .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin: 0; }
  .content.property .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    margin-right: 8px; }
  .content.property .ant-row {
    margin-bottom: 15px; }
  .content.property .actions {
    position: absolute;
    right: 20px;
    top: 25px; }
    .content.property .actions button {
      background: none;
      border: none;
      font-size: 20px;
      padding: 5px 10px; }
      .content.property .actions button.delete {
        color: #e08080; }
  .content.property button {
    background: none;
    border: none;
    font-size: 20px;
    padding: 5px 10px; }
    .content.property button.delete {
      color: #e08080; }

.fa-plus {
  color: white; }

.offer-detail .actions {
  right: 20px; }
  .offer-detail .actions button {
    background: none;
    border: none;
    font-size: 20px;
    padding: 5px 10px; }
    .offer-detail .actions button.delete {
      right: 20px;
      color: #e08080; }

.save-orders-table .table-container .ant-table-tbody .ant-table-expanded-row {
  background: none; }

@-moz-document url-prefix() {
  .table-container .Filters input {
    padding: 10px; }
  .otable-container .Filters .ant-input-group input {
    padding: 10px 0; } }

div.text {
  font-size: 16px; }

.wrapper {
    padding-top: 40px;
}
.categories-selection .Filters {
  padding: 0 !important; }

.categories-selection .seleziona-categoria {
  color: #555555;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: bold;
  line-height: 27px; }

.categories-selection .categorie-selezionat {
  color: #555555;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0; }
  .categories-selection .categorie-selezionat span {
    color: #653399;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 24px; }

.child-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
  padding: 10px 0;
}

.flex-centr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-centr .logo {
  width: 45px;
  height: 45;
  margin: 0px 40px 0 20px;
}

.flex-centr h4 {
  color: white;
  margin: 0;
  font-weight: 700;
}

.flex-centr p {
  color: white;
  margin: 0 20px 0 0;
}

.chat-block {
  display: flex;
  align-items: center;
}

.chat-block button {
  height: 45px;
  width: 45px;
  font-size: 20px;
  border-radius: 50%;
  border: 0;
  background: #ff8800;
  margin-left: 10px;
  cursor: pointer;
}
.chat-block button:focus {
  border: 0px;
  outline: 0;
}

.rlv-c-filter__field {
max-width: 14%;
margin-right: 1rem;
}
.rlv-c-filter__field:last-child {
margin-right: 0 !important;
}

.table-container .Filters {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 0 !important;
}

.col-1-2 {
    flex: 0 0 12.666667%;
    max-width: 12.666667%;
}
td.property-name {
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn.primary-outline {
    border: 2px solid #EF8634;
    color: #EF8634;    
    white-space: nowrap;
    height: 40px;
    padding: 0 1rem;
}
.label-color {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}
.confirm-order h1 {
  color: #555555;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: bold;
  line-height: 36px; }

.confirm-order p {
  color: #555555;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px; }

.nav-item .nav-link {
  color: #8569d0 !important;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
}

.nav-item .nav-link.active {
  color: #fff !important;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
}

.nav-item .nav-link:hover {
  color: #ffffff !important;
}

.ant-badge-dot {
  z-index: 10;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  top: 3px;
  right: 3px; }

.footer-title {
  margin: 0;
  font-size: 16px;
  color: white;
}

.footer-title a {
    color: #FFF !important;
  }

footer {
  height: 56px !important;
  background-color: #555555 !important;
  padding: 0 !important;
}

